/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

const $ = require('jquery');
global.$ = global.jQuery = $;

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything

require('bootstrap');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});

$(document).ready(function () {
    $('[data-bs-toggle="tooltip"]').tooltip();
});

const Highcharts = require('highcharts');  // or require('highcharts/highstock');
window.Highcharts = Highcharts;
require('highcharts/highcharts-more.js')(Highcharts);
require('highcharts/modules/accessibility.js')(Highcharts);
require('highcharts/modules/data.js')(Highcharts);
// require('highcharts/modules/exporting.js')(Highcharts);
require('highcharts/modules/export-data.js')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

require('bootstrap-datepicker/js/bootstrap-datepicker')
//require('bootstrap-datepicker/js/locales/bootstrap-datepicker.fr')
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css')
$(document).ready(function (){
    $('.input-daterange input').each(function () {
        $(this).datepicker({
            format: 'dd/mm/YYYY'
        });
    });
});

// Barecode scanner
import {Html5QrcodeScanner} from 'html5-qrcode';
window.Html5QrcodeScanner = Html5QrcodeScanner;

import autocolors from 'chartjs-plugin-autocolors';
